@use "monokai.scss"
@use "colorful.scss"

@font-face
  font-family: "PT Sans"
  src: local('PT Sans')
  src: url('/assets/fonts/PTSans-Regular.ttf')

@font-face
  font-family: "PT Sans Italic"
  src: local('PT Sans Italic')
  src: url('/assets/fonts/PTSans-Italic.ttf')
  font-style: italic

@font-face
  font-family: "PT Sans Bold"
  src: local('PT Sans Bold')
  src: url('/assets/fonts/PTSans-Bold.ttf')
  font-weight: bold

@font-face
  font-family: "PT Sans Bold Italic"
  src: local('PT Sans Bold Italic')
  src: url('/assets/fonts/PTSans-BoldItalic.ttf')
  font-weight: bold
  fount-style: italic

html
  --fg-color: #222
  --bg-color: #FFE
  overflow-x: hidden

  @media (prefers-contrast: more)
    --fg-color: black
    --bg-color: white

@media screen
  body
    font-family: sans-serif

    &>*:not(.full-width), &>.full-width>*
      max-width: 1000px
      margin-left: auto
      margin-right: auto

      padding-left: .5rem
      padding-right: .5rem

      @media (min-width: 768px)
        padding-left: 5rem
        padding-right: 5rem

  h1, h2, h3, h4, h5, h6, .main-nav
    font-family: 'PT Sans', sans-serif

  @media (max-width: 767px)
    nav.main-nav > a:not(:first-child)
      display: none
    .project-title
      flex-direction: column


@media (prefers-color-scheme: dark)
  html
    --fg-color: #DDD
    --bg-color: #22201A

    @media (prefers-contrast: more)
      --fg-color: white
      --bg-color: black

  a
    color: skyblue
    &:visited
      color: mediumpurple

  mark
    background-color: #774
    color: var(--fg-color)

body
  color: var(--fg-color)
  background-color: var(--bg-color)

  margin-left: 0
  margin-right: 0

h1
  margin-top: .5em
  margin-bottom: 0

.date, .byline
  font-size: 1.3em
  opacity: .7

blockquote
  border-left: 5px solid
  margin-left: 1em
  padding-left: 1em

img
  max-width: 100%
  border-radius: 5px

pre
  overflow-x: auto

.flex
  display: flex
.spacer
  flex-grow: 1

nav.main-nav
  display: flex
  font-size: 1.5em

  &>a
    color: var(--fg-color)
    margin: .5em 0
    margin-right: .7em
    text-decoration: none

  .social-links
    a
      text-decoration: none
    img
      height: 2em
      vertical-align: middle

      &.mastodon
        height: 1.3em
      &.github
        height: 1.2em


img.before
  border: 1px solid red
img.after
  border: 1px solid green

.project a, .footer-notice a
  padding: .7em

  display: flex
  flex-direction: row

  color: unset
  text-decoration: none

  border-radius: 1em
  transition: background 250ms

  img
    height: 4em
    width: 4em
    margin-right: 1em
    flex-shrink: 0
    border-radius: 5px

  h2
    margin: .1em 0

  @media (prefers-contrast: more)
    background: none
    border: 2px dotted transparent

.project:not(.hover-effect) a:hover
  background: linear-gradient(#7f7f7f33, #7f7f7f22)

  @media (prefers-contrast: more)
    background: none
    border: 2px dotted var(--fg-color)

.project .date
  font-size: 1em

.hover-effect
  a
    background-size: 100% 200%
    background-position-y: 0%

    transition: background-position-y 250ms

    @media (prefers-contrast: more)
      background: none !important

    &:hover
      background-position-y: 100%

      @media (prefers-contrast: more)
        border: 2px dotted var(--fg-color)

.project
  margin-bottom: 1em

  &.osm-in-realtime
    a
      display: flex
      align-items: center
      background-image: linear-gradient(#c4b04cbb 0%, #c4b04c77 50%, #be7e1e77 100%)
      @media (prefers-contrast: more)
        background: none !important
        box-shadow: inset 0 0 0 5px #c4b04c
    h2
      font-size: 2em
      margin-top: -4px
    img
      width: 5em
      height: 5em

  &.bookmarks-link
    a
      background-image: linear-gradient(#065e066b 0%, #28b1656b 50%, #08f3a560 100%)
      @media (prefers-contrast: more)
        background: none !important
        box-shadow: inset 0 0 0 5px #065e06
    img
      transform: translate(0.4em, -.7em)

    &.bookmarks-link-homepage
      a
        padding-bottom: 1em
      h2
        font-size: 2em
        margin-top: -2px

.footer-notice a
  color: #FFE
  margin: 2em .5em

  background: linear-gradient(#006dea 0%, #c500eaFF 50%, #EA4500AA 100%)
  background-size: 100% 200%
  background-position-y: 0%

  transition: background-position-y 250ms

  &:hover
    background-position-y: 100%

  h4
    margin-top: .2em
    margin-bottom: .5em
    font-size: 1.2em

  img
    align-self: center
    transition: transform 250ms
    margin-left: .5em

  &:hover img
    transform: scale(1.1)

  @media (prefers-contrast: more)
    background: none
    border: 8px solid blue
    color: var(--fg-color)

    &:hover
      border-style: dotted

.footer-notice-alt
  text-align: center
  margin-bottom: 2em

figure
  margin: 0
  figcaption
    opacity: .75
    font-style: italic


footer
  text-align: center
  margin: 3rem
  a, a:visited
    color: var(--fg-color)

.tabs
  .tabcontent
    margin-left: 9px

  .tabbar button
    cursor: pointer
    border: none
    background: none
    color: var(--fg-color)

    margin-right: 6px
    padding: 6px
    border-bottom: 3px solid transparent

    &[aria-selected="true"]
      border-color: var(--fg-color)

@media print
  nav.main-nav,
  footer,
  .no-print
    display: none

  body, h1
    margin-top: 0
    font-family: serif

  .link-footnote-marker a
    margin-left: 0.25em
    text-decoration: none !important

  .footer-notice a
    padding: 0
    margin: 0
    background: none
    color: unset

  .footer-notice-alt
    text-align: left
    margin-bottom: 0

  .tabs
    h4
      margin-bottom: 0

    .tabbar
      display: none

    .tabcontent
      display: unset
      margin-left: 0


@media not print
  .print-only
    display: none
